import React, { ReactElement, useState } from 'react';
import { PaymentMethodSelect } from './payment-method-select';
import { InvoiceDetail } from './invoice-detail';
import Section from './section';
import * as S from './styles';

import { CardPaymentMethodLayouts } from './card-payment-method-layouts';
import { PayNowBalanceView } from './pay-now-balance-view';
import { CardLoadingErrorSnackbar } from './card-loading-error-snackbar';
import { PaymentMethod, useStripePaymentMethods } from '../data';

export const PaymentMethodView = (
  {
    isShowPayNow = true,
    width = '45rem',
  }: {
    isShowPayNow?: boolean
    width?: string
  },
): ReactElement => {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);
  const [loadStripePaymentMethods, setLoadStripePaymentMethods] = useState(0);
  const stripePaymentMethodState = useStripePaymentMethods(loadStripePaymentMethods);

  const onReloadStripePaymentMethods = () => {
    setLoadStripePaymentMethods(new Date().getTime());
  };

  const isInvoice = (): boolean => paymentMethod === PaymentMethod.INVOICE;
  const isCard = (): boolean => paymentMethod === PaymentMethod.CARD;
  const isShowPayNowView = (): boolean => {
    const { isLoading, response } = stripePaymentMethodState;
    return isCard()
      && !isLoading
      && (response?.data?.hasCreditCard ?? false);
  };

  const isShowPayNowLayout = (): boolean => isShowPayNow && isShowPayNowView();

  return (
    <S.PaymentMethodView>
      <Section header="Payment Method" width={width}>
        <PaymentMethodSelect
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
        />
        {isCard() && (
          <CardPaymentMethodLayouts
            stripePaymentMethodState={stripePaymentMethodState}
            reloadStripePaymentMethods={onReloadStripePaymentMethods}
          />
        )}
        {isInvoice() && <InvoiceDetail />}
      </Section>
      {isShowPayNowLayout() && <PayNowBalanceView width={width} />}
      <CardLoadingErrorSnackbar state={stripePaymentMethodState} />
    </S.PaymentMethodView>
  );
};

PaymentMethodView.defaultProps = {
  isShowPayNow: true,
  width: '45rem',
};
