import React, { ReactElement } from 'react';
import * as S from './onboarding.styles';
import { Wizard } from '../../core';
import { Footer } from '../shared-ui/footer';
import { Sidebar } from '../shared-ui/sidebar';
import { StepBudget } from './step-budget';
import { StepPaymentMethod } from './step-payment-method';
import { StepWelcome } from './step-welcome';
import { StepContract } from '../shared-ui/step-contract';
import { StepAccountInfo } from './step-account-info';

const onboardingSteps = (): Map<number, string> => {
  const stepMap = new Map<number, string>();
  stepMap.set(0, 'Welcome!');
  stepMap.set(1, 'Account Information');
  stepMap.set(2, 'Budget');
  stepMap.set(3, 'Payment Method');
  stepMap.set(4, 'Service Agreement');
  return stepMap;
};

export const CustomerOnboarding = (): ReactElement => {
  const steps = onboardingSteps();
  return (
    <S.WizardContainer>
      <Wizard
        footer={<Footer />}
        header={<Sidebar />}
        stepMap={steps}
      >
        <StepWelcome />
        <StepAccountInfo />
        <StepBudget />
        <StepPaymentMethod />
        <StepContract title="Service Agreement" />
      </Wizard>
    </S.WizardContainer>
  );
};
