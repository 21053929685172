import React, { ReactElement } from 'react';
import * as S from './styles';
import { useCustomerListItem } from '../data/customer-list-item-context';
import { PaymentMethod } from '../../payments';

export const CardMissingIndicator = (): ReactElement => {
  const { paymentMethod, hasCreditCard } = useCustomerListItem();
  const isShowCardMissingIndicator = paymentMethod === PaymentMethod.CARD && !hasCreditCard;

  if (isShowCardMissingIndicator) {
    return (
      <>
        <S.Dot color="red" />
        <S.ListItemPaymentMethodAddition>
          Card missing
        </S.ListItemPaymentMethodAddition>
      </>
    );
  }
  return <div />;
};
