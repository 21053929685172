import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import { getAuthToken, isLoggedIn, logout } from '../auth/data';
import { Logger } from '../../shared/utils';

export const unauthorizedRequestHandler: Middleware = (
  api: MiddlewareAPI,
) => (next) => (action) => {
  if (action?.payload?.status === 401) {
    logout().then(() => Logger.log('Logged out - Byeee!'));
  }
  return next(action);
};

const authHeaders = (headers: Headers) => {
  if (isLoggedIn()) {
    headers.set('Authorization', `Token ${getAuthToken()}`);
  }
};

/**
 * Parses the response to camelCase
 * @param response
 */
const responseParser = (
  response: Response,
): Promise<any> => response.json().then((json) => camelcaseKeys(json, { deep: true }));

/**
 * Parses the body to snakeCase
 * @param body
 * TODO can we somehow make this a middleware?
 */
export const bodyParser = (body: any): any => snakecaseKeys(body);

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: authHeaders,
    responseHandler: responseParser,
  }),
  endpoints: (build) => ({}),
});

// TODO we could look into overriding fetchFn() and ahve the bodyParser there.

export type ApiErrorResponse = {
  status: number;
  data: {
    detail: string;
  }
}

export const isApiErrorResponse = (error: unknown): error is ApiErrorResponse => (
  typeof error === 'object'
  && error != null
  && 'status' in error
  && typeof (error as any).status === 'number'
);

export const isErrorWithMessage = (
  error: unknown,
): error is { message: string } => (
  typeof error === 'object'
  && error != null
  && 'message' in error
  && typeof (error as any).message === 'string'
);
