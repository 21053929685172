import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import { apiSlice } from '../features/api';
import { unauthorizedRequestHandler } from '../features/api/api-slice';
import {
  customerCheckoutSlice,
} from '../features/customers/customer-dashboard-checkout/customer-checkout-slice';
import { dialogSlice } from '../features/core/dialog/dialog-slice';
import { customerProfileSlice } from '../features/profile/customer-profile';

const preloadedState = {};

const listenerMiddleware = createListenerMiddleware();

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    dialog: dialogSlice.reducer,
    customerCheckout: customerCheckoutSlice.reducer,
    customerProfile: customerProfileSlice.reducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .prepend(listenerMiddleware.middleware)
    .concat(apiSlice.middleware)
    .concat(unauthorizedRequestHandler),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
