import React, { ReactElement } from 'react';
import { Button } from '@mui/material';
import { useAppDispatch } from '../../../app/hooks';
import { creditCardChargeDialogOpen } from './customer-checkout-slice';

export const ChargeCreditCardButton = (): ReactElement => {
  const dispatch = useAppDispatch();
  const onClick = () => {
    dispatch(creditCardChargeDialogOpen());
  };
  return (
    <Button variant="contained" color="blue" onClick={onClick}>
      CHARGE CREDIT CARD
    </Button>
  );
};
