import React, { ReactElement, useState } from 'react';
import { StatefulResponse } from '../../../shared/models';
import { Budget, CustomerDetailProfile, placeHolderBudget } from '../data';
import { BudgetEditCard } from './budget-edit-card';
import { BudgetDisplayCard } from './budget-display-card';

export const BudgetCard = (
  { state }: { state: StatefulResponse<CustomerDetailProfile> },
): ReactElement => {
  const [budget, setBudget] = useState<Budget>(state.response?.data.budget || placeHolderBudget);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const finishEditing = () => { setIsEditing(false); };
  const startEditing = () => { setIsEditing(true); };

  const budgetCard = isEditing
    ? <BudgetEditCard state={state} finishEditing={finishEditing} setBudget={setBudget} />
    : <BudgetDisplayCard startEditing={startEditing} budget={budget} />;

  return state.response?.data != null ? budgetCard : <div />;
};
