import { ApiMethod, ApiService } from './api-service';
import { GaaslyResponse } from '../../shared/models';
import { getAuthToken } from '../auth/data';
import { ApiObjectParser } from './api-object-parser';
import { Budget } from '../customers';

const API_URL = process.env.REACT_APP_API_URL;
const CUSTOMERS_URL = `${API_URL}/api/customers/`;

function getUpdateBudgetUrl(customerId: string): string {
  return `${CUSTOMERS_URL}${customerId}/budget-update/`;
}

export const updateBudget = (
  customerId: string,
  monthlyBudget: number,
): Promise<GaaslyResponse<Budget>> => {
  const payload = { monthlyBudget };
  const url = getUpdateBudgetUrl(customerId);

  return new ApiService<Budget>(url)
    .setMethod(ApiMethod.PUT)
    .setAuth(getAuthToken())
    .setRequestBody(payload)
    .setParser(new ApiObjectParser<Budget>())
    .request();
};
