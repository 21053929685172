import { apiSlice, PaginatedResponse } from '../../api';
import { TimeEntry } from '../../time-tracking/data';
import { ApiInvoice } from './types';

type GetCustomerTimeEntriesArgs = {
  customerId: string;
  startDate?: string;
  endDate?: string;
}

const getCustomerTimeEntriesUrl = (
  { customerId, startDate, endDate }: { customerId: string, startDate: string, endDate: string },
): string => {
  let url = `api/customers/${customerId}/time-entries/`;
  if (startDate && endDate) {
    url = `api/customers/${customerId}/time-entries`
      .concat(`?start_date=${startDate}`)
      .concat(`&end_date=${endDate}`);
  }
  return url;
};

type GetCustomerInvoicesArgs = {
  customerId: string;
}

type CheckoutCustomerArgs = {
  customerId: string,
}

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerTimeEntries:
      builder.query<PaginatedResponse<TimeEntry[]>, GetCustomerTimeEntriesArgs>({
        query: ({ customerId, startDate, endDate }) => ({
          url: `/api/customers/${customerId}/time-entries/`,
        }),
      }),
    getCustomerInvoices: builder.query<ApiInvoice[], GetCustomerInvoicesArgs>({
      query: ({ customerId }) => ({
        url: `api/customers/${customerId}/invoices/`,
      }),
    }),
    checkoutCustomer: builder.mutation<void, CheckoutCustomerArgs>({
      query: ({ customerId }) => ({
        url: `api/customers/${customerId}/checkout/`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetCustomerTimeEntriesQuery,
  useGetCustomerInvoicesQuery,
  useCheckoutCustomerMutation,
} = extendedApi;
