import React, { ReactElement } from 'react';

import * as S from './styles';
import { roundToTwoDecimals } from '../../../shared/utils';
import { useCustomerDetail } from '../../customers/data';

export const TimeInputBudget = (
  { selectedCustomerId }: {selectedCustomerId: string},
): ReactElement => {
  const customerState = useCustomerDetail(selectedCustomerId);

  if (customerState.isLoading
      || !customerState.response == null
      || !customerState.response?.data) {
    return <div />;
  }

  const { openBalance } = customerState.response.data;
  const { monthlyBudget } = customerState.response.data.budget;

  return (
    <S.TimeInputBalanceField>
      <S.CenteredText color="black">Client balance:</S.CenteredText>
      <S.CenteredText color="black">
        {' '}
        {roundToTwoDecimals(openBalance)}
        €
        {' '}
        /
        {' '}
        {monthlyBudget}
        €
        {' '}
      </S.CenteredText>
    </S.TimeInputBalanceField>
  );
};
