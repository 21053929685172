import React, { ReactElement, useEffect, useState } from 'react';
import { CloseOutlined } from '@mui/icons-material';
import * as S from './card.styles';
import { CardMainButton } from './card.styles';
import { StatefulResponse } from '../../../shared/models';
import { Budget, CustomerDetailProfile, useUpdateBudget } from '../data';

export const BudgetEditCard = (
  { state, finishEditing, setBudget }: {
      state: StatefulResponse<CustomerDetailProfile>,
      finishEditing: () => void,
      setBudget: (budget: Budget) => void,
    },
): ReactElement => {
  const [newMonthlyBudget, setNewMonthlyBudget] = useState<number>(0);
  const onNewBudgetChange = (event: any) => setNewMonthlyBudget(event.target.value);
  const [updateTrigger, setUpdateTrigger] = useState<number>(0);

  const customerId = state.response?.data.customerId || '';
  const budgetUpdateResponse = useUpdateBudget(updateTrigger, customerId, newMonthlyBudget);

  useEffect(() => {
    if (!budgetUpdateResponse.isLoading && budgetUpdateResponse.response != null) {
      setBudget(budgetUpdateResponse.response.data);
      finishEditing();
    }
  }, [budgetUpdateResponse]);

  const onSubmitBudgetChange = () => {
    setUpdateTrigger(new Date().getTime());
  };

  return (
    <S.Card>
      <S.CardTitle>Budget</S.CardTitle>
      <S.CardInputText
        name="budget-edit-input"
        variant="outlined"
        type="number"
        size="small"
        onChange={onNewBudgetChange}
        sx={{
          '& .MuiInputBase-root': { height: '1.8rem' },
        }}
      />
      <S.CardActionButton
        onClick={() => finishEditing()}
        size="small"
      >
        <CloseOutlined />
      </S.CardActionButton>
      <CardMainButton
        type="submit"
        color="primary"
        size="small"
        onClick={onSubmitBudgetChange}
        disabled={budgetUpdateResponse.isLoading || state.isLoading || state.response == null}
      >
        Save
      </CardMainButton>
    </S.Card>
  );
};
