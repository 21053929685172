import { placeholderTimeEntry, TimeEntry, TimeEntryRow } from './types';
import { GaaslyResponse } from '../../../shared/models';
import { timeEntriesToRows } from './utils';

export enum TimeEntryActionType {
  LOAD_LIST,
  LOAD_LIST_DONE,
  CREATE,
  CREATE_DONE,
  EDIT_OPEN,
  EDIT_CANCEL,
  EDIT,
  EDIT_DONE,
  EDIT_ERROR,
  DELETE_OPEN,
  DELETE_CANCEL,
  DELETE,
  DELETE_DONE,
  DELETE_ERROR,
}

export type TimeEntriesActions = {
  type: TimeEntryActionType,
  payload?: string | TimeEntry | GaaslyResponse<TimeEntry[]> |
    GaaslyResponse<TimeEntry> | GaaslyResponse<any>
}

export type TimeEntryCreateWarnings = {
  warnings: any
}

export type TimeEntriesState = {
  list: {
    isLoading: boolean,
    response: GaaslyResponse<TimeEntry[]> | null,
    timeEntryRows: TimeEntryRow[],
  },
  create: {
    isLoading: boolean,
    response: GaaslyResponse<TimeEntry> | GaaslyResponse<TimeEntryCreateWarnings> | null,
  }
  edit: {
    isLoading: boolean,
    response: GaaslyResponse<TimeEntry> | null,
    isDialogOpen: boolean,
    timeEntry: TimeEntry,
  },
  delete: {
    isLoading: boolean,
    response: GaaslyResponse<any> | null,
    isDialogOpen: boolean,
    timeEntry: TimeEntry,
  }
}

export const timeEntriesInitState = {
  list: {
    isLoading: false,
    response: null,
    timeEntryRows: [],
  },
  create: {
    isLoading: false,
    response: null,
  },
  edit: {
    isLoading: false,
    response: null,
    isDialogOpen: false,
    timeEntry: placeholderTimeEntry,
  },
  delete: {
    isLoading: false,
    response: null,
    isDialogOpen: false,
    timeEntry: placeholderTimeEntry,
  },
};

const findEntry = (id: string, entries: TimeEntry[]): TimeEntry => entries.find(
  (timeEntry) => timeEntry.uuid === id,
) ?? placeholderTimeEntry;

export function timeEntriesReducer(
  state: TimeEntriesState,
  action: TimeEntriesActions,
): TimeEntriesState {
  switch (action.type) {
    case TimeEntryActionType.LOAD_LIST: {
      return {
        ...state,
        list: { ...state.list, isLoading: true },
      };
    }
    case TimeEntryActionType.LOAD_LIST_DONE: {
      const response = action.payload as GaaslyResponse<TimeEntry[]>;
      const timeEntryRows = timeEntriesToRows(response.data);
      return {
        ...state,
        list: {
          ...state.list, response, timeEntryRows, isLoading: false,
        },
      };
    }
    case TimeEntryActionType.CREATE: {
      return { ...state, create: { ...state.create, isLoading: true } };
    }
    case TimeEntryActionType.CREATE_DONE: {
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          response: action.payload as GaaslyResponse<TimeEntry>,
        },
        list: { ...state.list, isLoading: true },
      };
    }
    case TimeEntryActionType.EDIT_OPEN: {
      const timeEntry = findEntry(action.payload as string, state.list.response!.data!);
      return {
        ...state,
        edit: {
          ...state.edit, isDialogOpen: true, timeEntry, response: null,
        },
      };
    }
    case TimeEntryActionType.EDIT_CANCEL: {
      return { ...state, edit: { ...state.edit, isDialogOpen: false, response: null } };
    }
    case TimeEntryActionType.EDIT: {
      return {
        ...state,
        edit: {
          ...state.edit, isLoading: true, response: null, timeEntry: action.payload as TimeEntry,
        },
      };
    }
    case TimeEntryActionType.EDIT_ERROR: {
      return {
        ...state,
        edit: {
          ...state.edit,
          isLoading: false,
          isDialogOpen: true,
          response: action.payload as GaaslyResponse<TimeEntry>,
        },
      };
    }
    case TimeEntryActionType.EDIT_DONE: {
      return {
        ...state,
        edit: {
          ...state.edit,
          isLoading: false,
          isDialogOpen: false,
          response: action.payload as GaaslyResponse<TimeEntry>,
        },
        list: { ...state.list, isLoading: true },
      };
    }
    case TimeEntryActionType.DELETE_OPEN: {
      const timeEntry = findEntry(action.payload as string, state.list.response?.data ?? []);
      return {
        ...state,
        delete: {
          ...state.delete, isDialogOpen: true, timeEntry, response: null,
        },
      };
    }
    case TimeEntryActionType.DELETE_CANCEL: {
      return { ...state, delete: { ...state.delete, isDialogOpen: false } };
    }
    case TimeEntryActionType.DELETE: {
      return { ...state, delete: { ...state.delete, isLoading: true } };
    }
    case TimeEntryActionType.DELETE_ERROR: {
      return {
        ...state,
        delete: {
          ...state.delete,
          isLoading: false,
          isDialogOpen: true,
          response: action.payload as GaaslyResponse<any>,
        },
      };
    }
    case TimeEntryActionType.DELETE_DONE: {
      return {
        ...state,
        delete: {
          ...state.delete,
          isLoading: false,
          isDialogOpen: false,
          response: action.payload as GaaslyResponse<any>,
        },
        list: { ...state.list, isLoading: true },
      };
    }
    default: {
      return timeEntriesInitState;
    }
  }
}
