import React, { useEffect, useState } from 'react';
import { updateBudget } from '../../api-legacy/update-budget';
import { StatefulResponse } from '../../../shared/models';
import { Budget } from './types';

export const useUpdateBudget = (
  updateTrigger: number,
  customerId: string,
  monthlyBudget: number,
): StatefulResponse<Budget> => {
  const [state, setState] = useState<StatefulResponse<Budget>>({
    isLoading: false,
    response: null,
  });

  useEffect(() => {
    if (updateTrigger > 0) {
      setState({ isLoading: true, response: null });
      updateBudget(customerId, monthlyBudget).then((response) => {
        setState({ isLoading: false, response });
      });
    }
  }, [updateTrigger]);

  return state;
};
