import React, { ReactElement, useEffect } from 'react';
import { Logger } from '../../../shared/utils';
import * as SA from '../shared-ui/content.styles';
import { useAuthUser } from '../../auth';
import { CustomerDetailProfile } from '../../customers';
import { useUpdateBudgetMutation } from '../../budget';
import { useWizard } from '../../core';

export const BudgetSave = (
  { monthlyBudget }: {
    monthlyBudget: number
  },
): ReactElement => {
  Logger.log('BudgetSaveButton()');
  const { setIsNextBlocked } = useWizard();
  const authUser = useAuthUser();
  const { customerId } = authUser as CustomerDetailProfile;
  const [updateBudget, updateBudgetRes] = useUpdateBudgetMutation();

  const {
    isLoading,
    isSuccess,
  } = updateBudgetRes;

  useEffect(() => {
    setIsNextBlocked(!isSuccess);
    return () => {
      setIsNextBlocked(false);
    };
  }, [updateBudgetRes, isSuccess]);

  const onSave = () => {
    updateBudget({ customerId, monthlyBudget });
  };

  return (
    <SA.LoadingButtonExtra
      variant="contained"
      color="blue"
      onClick={onSave}
      loading={isLoading}
      loadingPosition="start"
    >
      <span>Save</span>
    </SA.LoadingButtonExtra>
  );
};
