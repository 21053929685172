import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

export enum DialogStatus {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum DialogResult {
  CANCEL = 'CANCEL',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export interface DialogClosePayload {
  tag: string;
  result: DialogResult;
}

export interface DialogState {
  tag: string;
  isOpen: boolean;
  status: DialogStatus;
  title: string;
  loadingText: string;
  successText: string;
  errorText: string;
  positiveActionText: string;
}

export const initialState: DialogState = {
  tag: '',
  isOpen: false,
  status: DialogStatus.INITIAL,
  title: '',
  loadingText: '',
  successText: '',
  errorText: '',
  positiveActionText: '',
};

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    dialogOpen: (state, action: PayloadAction<DialogState>) =>
      // eslint-disable-next-line no-param-reassign,implicit-arrow-linebreak
      ({ state, ...action.payload }),
    dialogClose: (state, action: PayloadAction<DialogClosePayload>) => {
      state.isOpen = false;
    },
    dialogStatusUpdate: (state, action: PayloadAction<DialogStatus>) => {
      state.status = action.payload;
    },
    positiveAction: (state) => ({ ...state }),
    errorTextUpdate: (state, action) => {
      state.errorText = action.payload;
    },
  },
});
export const {
  dialogOpen,
  dialogClose,
  dialogStatusUpdate,
  positiveAction,
  errorTextUpdate,
} = dialogSlice.actions;

export const selectDialogTag = (state: RootState) => state.dialog.tag;

export const selectDialogIsOpen = (state: RootState) => state.dialog.isOpen;

export const selectDialogStatus = (state: RootState) => state.dialog.status;

export const selectDialogTitle = (state: RootState) => state.dialog.title;

export const selectDialogLoadingText = (state: RootState) => state.dialog.loadingText;

export const selectDialogSuccessText = (state: RootState) => state.dialog.successText;

export const selectDialogErrorText = (state: RootState) => state.dialog.errorText;

export const selectDialogPositiveActionText = (state: RootState) => state.dialog.positiveActionText;

export default dialogSlice.reducer;

/* Helper methods */
export const getDialogStatus = (
  result: { isLoading: boolean, isError: boolean, isSuccess: boolean },
): DialogStatus => {
  const { isLoading, isError, isSuccess } = result;
  if (isLoading) {
    return DialogStatus.LOADING;
  }
  if (isSuccess) {
    return DialogStatus.SUCCESS;
  }
  if (isError) {
    return DialogStatus.ERROR;
  }
  return DialogStatus.INITIAL;
};
