import React, {
  ReactElement, useEffect, useReducer, useState,
} from 'react';
import { Container, CssBaseline } from '@mui/material';
import {
  TimeEntriesActions,
  timeEntriesInitState,
  timeEntriesReducer,
  TimeEntriesState,
} from '../data/reducers';
import { NotificationsView } from '../../notifications';
import * as S from './styles';
import { TimeBalanceView } from './time-balance-view';
import { TimeTrackingInput } from './time-tracking-input';
import { TimeEntryList } from './time-entry-list';
import { TimeTrackingWarningsDialog } from './time-tracking-warnings-dialog';

export const TimeTrackingPage = (): ReactElement => {
  const [state, dispatch] = useReducer<(s: TimeEntriesState, a: TimeEntriesActions
  ) => TimeEntriesState>(timeEntriesReducer, timeEntriesInitState);
  const [loadTimeBalance, setLoadTimeBalance] = useState<number>(0);
  useEffect(() => {
    if (state.list.isLoading) {
      setLoadTimeBalance(new Date().getTime());
    }
  }, [state.list]);

  return (
    <Container component="main" maxWidth={false}>
      <CssBaseline />
      <S.InfoContainer>
        <NotificationsView />
        <TimeBalanceView loadTimeBalance={loadTimeBalance} />
      </S.InfoContainer>
      <TimeTrackingInput state={state} dispatch={dispatch} />
      <TimeEntryList state={state} dispatch={dispatch} />
      <TimeTrackingWarningsDialog state={state} />
    </Container>
  );
};
