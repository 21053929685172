import React, { ReactElement } from 'react';
import { EditOutlined, InfoOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import * as S from './card.styles';
import { CardActionButton } from './card.styles';
import { Budget } from '../data';
import { formatDateForUi } from '../../../shared/utils';

export const BudgetDisplayCard = (
  { startEditing, budget }: {
      startEditing: () => void,
      budget: Budget
    },
): ReactElement => {
  const getUpdatedByInfo = (): string => {
    const updatedBy = `${budget.updatedBy?.firstName || ''} ${budget.updatedBy?.lastName || ''}`;
    const date = formatDateForUi(new Date(budget.updatedAt));
    return `Updated by ${updatedBy} - ${date}`;
  };
  return (
    <S.Card>
      <S.CardTitle>Budget</S.CardTitle>
      <S.CardValue>
        {budget.monthlyBudget}
        €
      </S.CardValue>
      <S.CardToolTip title={getUpdatedByInfo()}>
        <IconButton size="small">
          <InfoOutlined />
        </IconButton>
      </S.CardToolTip>
      <CardActionButton
        onClick={() => startEditing()}
        size="small"
      >
        <EditOutlined />
      </CardActionButton>
    </S.Card>
  );
};
