import { placeholderUser, UserProfile } from '../../../shared/models';
import { PaymentMethod } from '../../payments';
import { roundToTwoDecimals } from '../../../shared/utils';

export type ApiInvoice = {
  createdDate: Date,
  dueDate: Date | null,
  invoiceNumber: string,
  status: InvoiceStatus,
  invoicePdf: string,
  total: number,
  currency: string,
}

export enum InvoiceStatus {
  OPEN = 'open',
  PAID = 'paid',
  OVERDUE = 'overdue',
}

export class Invoice {
  private createdDate: Date;

  private dueDate: Date | null;

  private invoiceNumber: string;

  private status: InvoiceStatus;

  private invoicePdfUrl: string;

  private amount: number;

  private currency: string;

  constructor(apiInvoice: ApiInvoice) {
    this.createdDate = new Date(apiInvoice.createdDate);
    if (apiInvoice.dueDate === null) {
      this.dueDate = null;
    } else {
      this.dueDate = new Date(apiInvoice.dueDate);
    }
    this.invoiceNumber = apiInvoice.invoiceNumber;
    this.status = apiInvoice.status;
    this.invoicePdfUrl = apiInvoice.invoicePdf;
    this.amount = apiInvoice.total;
    this.currency = apiInvoice.currency;
  }

  public getAmount(): number {
    return this.amount;
  }

  public getCurrency(): string {
    return this.currency;
  }

  public getCreated(): Date {
    return this.createdDate;
  }

  public getDueDate(): Date | null {
    return this.dueDate;
  }

  public getInvoiceNumber(): string {
    return this.invoiceNumber;
  }

  public getFormattedAmount(): string {
    return `${roundToTwoDecimals(this.amount)} ${this.currency}`;
  }

  public getFormattedCreatedDate(): string {
    return this.createdDate.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }

  public getFormattedDueDate(): string {
    if (this.dueDate === null) return '';
    return this.dueDate.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }

  public getStatus(): InvoiceStatus {
    return this.status;
  }

  public getInvoicePdfUrl(): string {
    return this.invoicePdfUrl;
  }
}

export type Budget = {
  monthlyBudget: number,
  updatedAt: string,
  updatedBy: UserProfile,
}

export const placeHolderBudget: Budget = {
  monthlyBudget: 0,
  updatedAt: '',
  updatedBy: placeholderUser,
};

export interface CustomerDetailProfile extends UserProfile {
  customerId: string,
  invoicingEmail: string,
  addressLine1: string,
  addressLine2: string,
  city: string,
  postalCode: string,
  country: string,
  isCompany: boolean,
  companyName: string,
  vatId: string,
  hasCreditCard: boolean,
  hourlyPriceEur: number,
  openBalance: number,
  paymentMethod: PaymentMethod,
  budget: Budget,
  additionalBillingInfo: string,
}

export const placeholderCustomer: CustomerDetailProfile = {
  ...placeholderUser,
  customerId: '',
  invoicingEmail: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  postalCode: '',
  country: '',
  isCompany: true,
  companyName: '',
  vatId: '',
  hasCreditCard: false,
  hourlyPriceEur: 70.0,
  openBalance: 0.0,
  emailChanged: false,
  paymentMethod: PaymentMethod.CARD,
  budget: placeHolderBudget,
  additionalBillingInfo: '',
};

export type CustomerListProfile = {
  userId: string,
  customerId: string,
  email: string,
  firstName: string,
  lastName: string,
  companyName: string,
  hourlyPriceEur: number,
  hasCreditCard: boolean,
  paymentMethod: PaymentMethod,
}

export const placeholderCustomerListProfile: CustomerListProfile = {
  userId: '',
  customerId: '',
  email: '',
  firstName: '',
  lastName: '',
  companyName: '',
  hourlyPriceEur: 70,
  hasCreditCard: false,
  paymentMethod: PaymentMethod.INVOICE,
};
