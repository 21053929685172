import { apiSlice } from '../../api';
import { bodyParser } from '../../api/api-slice';

const budgetAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateBudget: builder.mutation<string, { customerId: string, monthlyBudget: number }>({
      query: ({ customerId, monthlyBudget }) => ({
        url: `api/customers/${customerId}/budget-update/`,
        method: 'PUT',
        body: bodyParser({ monthlyBudget }),
      }),
    }),
  }),
});

export const {
  useUpdateBudgetMutation,
} = budgetAPI;
