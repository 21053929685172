import React, { ReactElement, useEffect, useState } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import {
  GaaslyError, StatefulResponse,
} from '../models';
import * as S from '../../features/theme/styles';
import { CustomerListProfile } from '../../features/customers';
import { useCustomerList } from '../../features/customers/data';
import { PaymentMethod } from '../../features/payments';

export const CustomerSelectSearch = (
  { errors, defaultCustomer, setCustomer }: {
    errors?: GaaslyError | null,
    defaultCustomer?: CustomerListProfile | null,
    setCustomer: (customer: CustomerListProfile) => void
  },
): ReactElement => {
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const customersResponse: StatefulResponse<CustomerListProfile[]> = useCustomerList(searchKeyword);
  const [customers, setCustomers] = useState<CustomerListProfile[]>([]);
  const initCustomer = defaultCustomer || null;
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerListProfile | null>(
    initCustomer,
  );

  useEffect(() => {
    if (!customersResponse.isLoading && customersResponse.response) {
      setCustomers(customersResponse.response.data);
    }
  }, [customersResponse]);

  useEffect(() => {
    if (selectedCustomer) {
      setCustomer(selectedCustomer);
    }
  }, [selectedCustomer]);

  const getApiErrors = () => errors?.getErrors() || {};
  const hasApiErrors = () => Object.prototype.hasOwnProperty.call(getApiErrors(), 'customerId');
  const isCardMissing = (): boolean => selectedCustomer !== null && !selectedCustomer.hasCreditCard;
  const isCardPaymentMethod = ():
      boolean => selectedCustomer !== null
      && selectedCustomer?.paymentMethod === PaymentMethod.CARD;
  const showCardError = (): boolean => isCardMissing() && isCardPaymentMethod();

  const getErrors = (): string => {
    if (hasApiErrors()) {
      return getApiErrors().customerId;
    }
    if (showCardError()) {
      return 'Credit card missing';
    }
    return '';
  };

  return (
    <S.SelectFormControl
      variant="outlined"
    >
      {/* <InputLabel id="customers-select-label">Customer</InputLabel> */}
      <Autocomplete<CustomerListProfile>
        id="customers-select-search"
        style={{ width: 250 }}
        options={customers}
        getOptionLabel={(option: CustomerListProfile) => option.email}
        loading={customersResponse.isLoading}
        value={selectedCustomer}
        onChange={(event: any, customer: CustomerListProfile | null | undefined) => {
          if (customer) {
            setSelectedCustomer(customer);
          }
        }}
        inputValue={searchKeyword}
        onInputChange={(event, newInputValue) => {
          setSearchKeyword(newInputValue);
        }}
        renderInput={(params) =>

        /* eslint-disable react/jsx-props-no-spreading,implicit-arrow-linebreak */
          (
            <TextField
              {...params}
              variant="outlined"
              label="Customer"
              error={hasApiErrors() || showCardError()}
              helperText={getErrors()}
            />
          )}
          /* eslint-enable react/jsx-props-no-spreading,implicit-arrow-linebreak */
      />
    </S.SelectFormControl>
  );
};

CustomerSelectSearch.defaultProps = {
  defaultCustomer: null,
  errors: null,
};
