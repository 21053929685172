import React, { ReactElement, useEffect, useState } from 'react';
import { SimpleDialog } from '../../core/simple-dialog';
import { TimeEntriesState } from '../data';
import { getValue } from '../../core';
import { CustomerInfoWarnings } from './customer-info-warnings';
import { ContractWarning } from './contract-warning';

export const TIME_TRACKING_DIALOG_TAG = 'time-tracking';

export const TimeTrackingWarningsDialog = (
  { state }: { state: TimeEntriesState },
): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const warnings = getValue(state.create.response?.data, 'warnings');

  useEffect(() => {
    const hasWarnings = warnings !== undefined;
    setIsOpen(hasWarnings);
  }, [state]);

  const onCloseDialog = () => setIsOpen(false);

  return (
    <div>
      {warnings && (
        <SimpleDialog
          isOpen={isOpen}
          onCloseDialog={onCloseDialog}
          title="Customer action needed "
        >

          <CustomerInfoWarnings warnings={warnings} />
          <ContractWarning warnings={warnings} />
        </SimpleDialog>
      )}
    </div>
  );
};
