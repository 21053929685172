import React, { ReactElement } from 'react';
import { useAppSelector } from '../../../app/hooks';
import {
  selectShowChargeCreditCardDialog,
  selectShowSendInvoiceDialog,
} from './customer-checkout-slice';
import { SendInvoiceDialog } from './send-invoice-dialog';
import { ChargeCreditCardDialog } from './charge-credit-card-dialog';

export const CheckoutDialog = (
  { customerId }: { customerId: string },
): ReactElement => {
  const isShowInvoiceDialog = useAppSelector(selectShowSendInvoiceDialog);
  const isShowChargeDialog = useAppSelector(selectShowChargeCreditCardDialog);
  return (
    <>
      {isShowInvoiceDialog && <SendInvoiceDialog customerId={customerId} />}
      {isShowChargeDialog && <ChargeCreditCardDialog customerId={customerId} />}
    </>
  );
};
