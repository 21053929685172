import React, { ReactElement, useState } from 'react';
import { Logger } from '../../../shared/utils';
import * as S from '../shared-ui/content.styles';
import { BudgetSave } from './budget-save';

export const StepBudget = (): ReactElement => {
  Logger.log('StepBudget()');
  const [monthlyBudget, setMonthlyBudget] = useState<number>(0);

  const onBudgetChange = (event: any) => {
    setMonthlyBudget(event.target.value);
  };

  return (
    <>
      <S.Header>
        Budget
      </S.Header>
      <S.HorizontallyCenteredContent>
        <S.CenteredText>
          Specify the monthly budget for consulting services only. Please note, this does not
          include any media budget. You can update this amount at any time.
        </S.CenteredText>
        <S.CenterVertical>
          <S.BudgetField
            inputProps={{ type: 'number' }}
            variant="outlined"
            margin="normal"
            required
            id="budget"
            label="Monthly budget in Euros"
            name="budget"
            autoComplete="budget"
            autoFocus
            type="number"
            onChange={onBudgetChange}
          />
          <BudgetSave monthlyBudget={monthlyBudget} />
        </S.CenterVertical>
      </S.HorizontallyCenteredContent>
    </>
  );
};
