import React, { ReactElement } from 'react';
import { CustomerDetailProfile } from '../data';
import { PaymentMethod } from '../../payments';
import { ChargeCreditCardButton } from './charge-credit-card-button';
import { SendInvoiceButton } from './send-invoice-button';
import { CreditCardMissingButton } from './credit-card-missing-button';

export const CheckoutButton = (
  { customer }: { customer: CustomerDetailProfile },
): ReactElement => {
  const { paymentMethod, hasCreditCard } = customer;

  if (customer.openBalance === 0) {
    return <div />;
  }
  if (PaymentMethod.CARD === paymentMethod && hasCreditCard) {
    return <ChargeCreditCardButton />;
  }
  if (PaymentMethod.CARD === paymentMethod && !hasCreditCard) {
    return <CreditCardMissingButton />;
  }
  return <SendInvoiceButton />;
};
