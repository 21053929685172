import { useEffect } from 'react';
import { addListener } from '@reduxjs/toolkit';
import {
  dialogClose,
  dialogOpen,
  DialogResult,
  dialogStatusUpdate,
  errorTextUpdate,
  getDialogStatus,
  positiveAction,
} from '../../core/dialog/dialog-slice';
import { useAppDispatch } from '../../../app/hooks';
import { isApiErrorResponse } from '../../api/api-slice';
import { SEND_INVOICE_DIALOG_TAG } from './send-invoice-dialog';

export const useCheckoutOnPositiveAction = (
  customerId: string,
  checkout: ({ customerId }: { customerId: string }) => void,
) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const unsubscribe = dispatch(addListener({
      actionCreator: positiveAction,
      effect: () => {
        checkout({ customerId });
      },
    }));
    return unsubscribe;
  }, []);
};

export const useUpdateDialogStatus = (
  result: any,
) => {
  const dispatch = useAppDispatch();
  const { error } = result;

  useEffect(() => {
    if (isApiErrorResponse(error)) {
      const errorText = 'Error: '.concat(error.data.detail);
      dispatch(errorTextUpdate(errorText));
    }
    const newDialogStatus = getDialogStatus(result);
    dispatch(dialogStatusUpdate(newDialogStatus));
  }, [result]);
};

export const useInitDialog = (
  dialogDetails: any,
) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(dialogOpen(dialogDetails));

    return () => {
      dispatch(dialogClose({
        tag: SEND_INVOICE_DIALOG_TAG,
        result: DialogResult.CANCEL,
      }));
    };
  }, []);
};
